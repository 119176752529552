import React from "react";

import Header from "../components/header";
import Footer from "../components/footer";

interface LayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      {/** Header */}
      <div className="max-w-screen-xl mx-auto px-5">
        <Header />
      </div>

      {/** Page */}
      <div className="max-w-screen-xl mx-auto px-5">{children}</div>

      {/** Footer */}
      <Footer />
    </>
  );
};

export default Layout;
