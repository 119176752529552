import * as React from "react";
import { Link } from "gatsby";

interface HeaderProps {}

const links = [
  { href: "#contact-us", title: "Services" },
  { href: "#contact-us", title: "Process" },
  { href: "#contact-us", title: "Team" },
];

const Header: React.FunctionComponent<HeaderProps> = () => {
  return (
    <header className="flex flex-col lg:flex-row justify-between items-center mb-5 mt-7 lg:mt-11">
      <div className="flex w-full lg:w-auto items-center justify-between">
        <Link to="/" className="text-lg">
          <span className="font-bold text-slate-800">Robots</span>
          <span className="text-slate-500">everywhere</span>
        </Link>

        <div className="block lg:hidden">
          <Link
            to="#contact-us"
            className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800 border-2 border-transparent"
          >
            Get Started
          </Link>
        </div>
      </div>

      <nav className="astronav-items astronav-toggle w-full lg:w-auto mt-2 lg:flex lg:mt-0 hidden">
        <ul className="flex flex-col lg:flex-row lg:gap-3">
          {links.map((item) => (
            <li key={item.title}>
              <Link
                to={item.href}
                className="flex lg:px-3 py-2 items-center text-gray-600 hover:text-gray-900"
              >
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>

      <div className="hidden lg:block">
        <Link
          to="#contact-us"
          className="rounded text-center transition focus-visible:ring-2 ring-offset-2 ring-gray-200 px-4 py-2 bg-black text-white hover:bg-gray-800 border-2 border-transparent"
        >
          Get Started
        </Link>
      </div>
    </header>
  );
};

export default Header;
