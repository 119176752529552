import * as React from "react";

import siteConfig from "../../const/site-config";

const Footer: React.FC = () => {
  return (
    <footer className="my-20">
      <p className="text-center text-sm text-slate-500">
        {`Copyright © ${new Date().getFullYear()} ${
          siteConfig.siteName
        }. All rights reserved.`}
      </p>
    </footer>
  );
};

export default Footer;
